import {lazy} from 'react'

const LandingPage = lazy(async () => await import('pages/LandingPage'))
const GetStarted = lazy(async () => await import('pages/GetStarted'))
const Register = lazy(async () => await import('pages/Register'))
const Login = lazy(async () => await import('pages/Login'))
const VerifyEmail = lazy(async () => await import('pages/VerifyEmail'))
const ForgotPassword = lazy(async () => await import('pages/ForgotPassword'))
const ResetPassword = lazy(async () => await import('pages/ResetPassword'))
const SetupAccount = lazy(async () => await import('pages/SetupAccount'))
const SetupBusiness = lazy(async () => await import('pages/SetupBusiness'))
const SetupComleted = lazy(async () => await import('pages/SetupCompleted'))
const AuthVerify = lazy(async () => await import('pages/AuthVerify'))
const Profile = lazy(async () => await import('pages/Profile'))
const ProfilePublic = lazy(async () => await import('pages/ProfilePublic'))
const Jobs = lazy(async () => await import('pages/Jobs'))
//const PublicJobPost = lazy(async () => await import('pages/PublicJobPost'))
const NotFound = lazy(async () => await import('pages/NotFound'))
const PrivacyPolicy = lazy(async () => await import('pages/PrivacyPolicy'))

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    exact: true,
    component: LandingPage,
    protected: false,
  },
  {
    path: '/get-started',
    name: 'GetStarted',
    exact: true,
    component: GetStarted,
    protected: false,
  },
  {
    path: '/register',
    name: 'Register',
    exact: true,
    component: Register,
    protected: false,
  },
  {
    path: '/login',
    name: 'Login',
    exact: false,
    component: Login,
    protected: false,
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    exact: true,
    component: VerifyEmail,
    protected: false,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    exact: true,
    component: ForgotPassword,
    protected: false,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    exact: false,
    component: ResetPassword,
    protected: false,
  },
  {
    path: '/setup-business',
    name: 'SetupBusiness',
    exact: true,
    component: SetupBusiness,
    protected: true,
  },
  {
    path: '/setup-account',
    name: 'SetupAccount',
    exact: true,
    component: SetupAccount,
    protected: true,
  },
  {
    path: '/setup-completed',
    name: 'SetupCompleted',
    exact: true,
    component: SetupComleted,
    protected: true,
  },
  {
    path: '/auth/callback',
    name: 'AuthVerify',
    exact: true,
    component: AuthVerify,
    protected: true,
  },
  {
    path: '/profile',
    name: 'Profile',
    exact: true,
    component: Profile,
    protected: true,
  },
  {
    path: '/profile/:id',
    name: 'Profile Public',
    exact: false,
    component: ProfilePublic,
    protected: false,
  },
  {
    path: '/job/:jobId',
    name: 'JobPost',
    exact: false,
    component: Profile,
    protected: true,
  },
  {
    /*
    path: '/job/:jobId/preview',
    name: 'PreviewJobPost',
    exact: false,
    component: PublicJobPost,
    protected: false,
*/
  },
  {
    path: '/create-job-post/:jobId',
    name: 'CreateJobPost',
    exact: false,
    component: Profile,
    protected: true,
  },
  {
    path: '/edit-job-post/:jobId',
    name: 'EditJobPost',
    exact: false,
    component: Profile,
    protected: true,
  },
  {
    path: '/jobs',
    name: 'Jobs',
    exact: false,
    component: Jobs,
    protected: true,
  },
  {
    path: '/profiles',
    name: 'Profiles',
    exact: false,
    component: Profile,
    protected: true,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    exact: true,
    component: PrivacyPolicy,
    protected: false,
  },
  {
    path: '*',
    name: 'NotFound',
    exact: false,
    component: NotFound,
    protected: false,
  },
]

export default routes
